<template>
  <div class="audience-age-and-gender d-lg-flex flex-column min-height-100">
    <div class="text-h6 font-weight-bold mb-4">
      Age &amp; Gender Split
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          We determine historical followers age and gender by analyzing the profile picture, text in the profile description (bio) and selfied in recent posts. Only available for the audience as an aggregated percentage.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft flex-grow-1">
      <v-card-text class="pb-lg-0">
        <!-- show the options to switch -->
        <v-tabs
          show-arrows
          @change="(index) => selectedIndex = index"
        >
          <v-tab
            v-for="item in items"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>

        <!-- render the chart component -->
        <template v-if="selectedIndex === 0">
          <gender-split
            :audience-data="audienceData"
            :height="295"
          />
        </template>
        <template v-else>
          <age-and-gender :audience-data="audienceData" />
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          small
          color="primary"
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="shouldShowDialog"
      :width="selectedIndex === 0 ? 600 : 800"
    >
      <v-card>
        <v-card-title>
          {{ selectedIndex === 0  ? "Gender Split" : "Age Split" }}
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            hide-default-footer
            disable-pagination
          ></v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import child components
const GenderSplit = () => import(/* webpackChunkName: "charts-gender-split" */ "@/blocks/user-profile/charts/GenderSplit.vue")
const AgeAndGender = () => import(/* webpackChunkName: "charts-age-gender" */ "@/blocks/user-profile/charts/AgeAndGender.vue")

// Export the SFC
export default {
  // Name of the component
  name: "AudienceAgeAndGender",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true
    },

    allData: {
      type: Object,
      required: true
    },

    audienceData: {
      type: Object,
      required: true
    },

    totalValues: {
      type: Object,
      required: true
    },

    allTabs: {
      type: Array,
      required: true
    },

    selectedTab: {
      type: String,
      required: true
    }
  },

  // Register the components
  components: {
    GenderSplit,
    AgeAndGender
  },

  // Define local data variables
  data: () => ({
    selectedIndex: 0,
    shouldShowDialog: false,

    // Tab options
    items: ["Gender Split", "Age & Gender"]
  }),

  // Define readonly computable properties
  computed: {
    /**
     * Whether or not the current profile's platform is youtube
     *
     * @returns {Boolean}
     */
    isYoutube() {
      return this.platform === "youtube"
    },

    /**
     * Get the table headers based on the selected tab
     *
     * @returns {Array}
     */
    tableHeaders() {
      if (this.selectedIndex === 0) {
        return [
          {
            text: "Gender",
            value: "text",
            sortable: false
          },
          {
            text: "Followers",
            value: "followers",
            sortable: true
          },
          {
            text: this.isYoutube ? "Comments" : "Likes",
            value: this.isYoutube ? "comments" : "likes",
            sortable: true
          },
        ]
      }
      else {
        return [
          {
            text: "Age",
            value: "age",
            sortable: true
          },
          {
            text: "Followers",
            value: "followers",
            sortable: true
          },
          {
            text: this.isYoutube ? "Comments" : "Likes",
            value: this.isYoutube ? "comments" : "likes",
            sortable: true
          },
          {
            text: "Male",
            value: "male",
            sortable: true,
          },
          {
            text: "Female",
            value: "female",
            sortable: true,
          }
        ]
      }
    },

    /**
     * Get the table row items
     *
     * @returns {Array}
     */
    tableItems() {
      const finalArray = []

      for (const tab of this.allTabs) {
        // For Gender
        if (this.selectedIndex === 0) {
          for (const iteratorItem of this.allData[tab]["gender"]) {
            const textValue = capitalizeString(iteratorItem.code)

            // check if an item with text is not already there, add a default one
            if (finalArray.findIndex((search) => search.text === textValue) === -1) {
              finalArray.push({
                text: textValue,
                followers: "",
                comments: "",
                likes: ""
              })
            }

            // Now find the item and update it
            const foundItem = finalArray.find((search) => search.text === textValue)

            foundItem[tab] = `${numeral.fNumber( this.totalValues[tab] * iteratorItem.weight )} / ${numeral.fPercent(iteratorItem.weight * 100)}`
          }
        }
        // For Age
        else {
          for (let index = 0; index < this.allData[tab]?.age?.female?.length; index++) {
            const maleValue = this.allData[tab]?.age?.male[index]
            const femaleValue = this.allData[tab]?.age?.female[index]

            // Check if an item with text is not already there, add a default one
            if (finalArray.findIndex((search) => search.age === maleValue.x) === -1) {
              finalArray.push({
                age: maleValue.x,
                followers: "",
                comments: "",
                likes: ""
              })
            }

            // Now find the item and update it
            const foundItem = finalArray.find((search) => search.age === maleValue.x)

            foundItem[tab] = `${numeral.fNumber( this.totalValues[tab] * (maleValue.y + femaleValue.y) / 100 )}`
            foundItem["male"] = `${numeral.fNumber( this.totalValues[tab] * maleValue.y / 100 )} / ${numeral.fPercent(maleValue.y)}`
            foundItem["female"] = `${numeral.fNumber( this.totalValues[tab] * femaleValue.y / 100 )} / ${numeral.fPercent(femaleValue.y)}`
          }
        }
      }

      return finalArray.slice(0, finalArray.length - 1)
    },
  }
}
</script>
